<template>
  <div class="berita">
    <Navbar />
    <Welcome
      :deskripsi="layout.deskripsi"
      :nama_sekolah="layout.nama_sekolah"
    />
    <div class="container">
      <div class="row py-3">
        <div class="col-md-12 mb-4">
          <h2 class="header"><i class="bi-newspaper"></i> Semua Berita</h2>
          <div class="row mt-4">
            <div
              class="col-md-6 col-lg-4 mb-3"
              v-for="news in news"
              :key="news.id"
            >
              <CardBerita
                :title="news.title"
                :thumbnail="news.thumbnail"
                :published="news.published"
                :slug="news.slug"
              ></CardBerita>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-6 col-lg-4">
          <h2 class="header"><i class="bi-tags"></i> Tags</h2>
          <div class="row mt-4">
            <div class="col mb-3">
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <Footer :tentang="layout.tentang_sekolah" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Berita",
  components: {
    Navbar: () => import("@/components/Navbar.vue"),
    Welcome: () => import("@/components/Welcome.vue"),
    CardBerita: () => import("@/components/CardBerita.vue"),
    // ButtonTags: () => import("@/components/ButtonTags.vue"),
    Footer: () => import("@/components/Footer.vue"),
  },

  data() {
    return {
      news: [],
      layout: [],
    };
  },

  mounted() {
    this.getLayout();
    this.getNews();
  },

  methods: {
    async getNews() {
      let response = await axios.get("news");
      this.news = response.data.data;
    },
    async getLayout() {
      let response = await axios.get("layout");
      this.layout = response.data.data;
    },
  },
};
</script>
